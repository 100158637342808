import React, { useState, useEffect, useRef  } from 'react';
import axios from 'axios';
import CompanyKeyForm from './CompanyKeyForm';
import ShowRecords from './ShowRecords';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const effectRan = useRef(false);

  useEffect(() => {
    if (effectRan.current) return; // Prevents the effect from running twice
    effectRan.current = true;
    // On mount, check if there's an authorization code in the URL
    const query = new URLSearchParams(window.location.search);
    const authCode = query.get('code');

    if (authCode) {
      exchangeAuthCodeForTokens(authCode);
    } else {
      const token = localStorage.getItem('idToken');
      if (token) {
        verifyToken(token);
      } else {
        redirectToCognitoLogin();
      }
    }
  }, []);

  const exchangeAuthCodeForTokens = async (code) => {
    try {
      const response = await axios.post('/api/exchange-token', { code });
  
      const { id_token, access_token, refresh_token } = response.data;
  
      // Store tokens in localStorage
      localStorage.setItem('idToken', id_token);
      localStorage.setItem('accessToken', access_token);
      localStorage.setItem('refreshToken', refresh_token);
      setIsAuthenticated(true);
      window.history.replaceState({}, document.title, "/");
    } catch (error) {
      console.error('Error exchanging authorization code:', error.response ? error.response.data : error.message);
      redirectToCognitoLogin();
    }
  };

  const verifyToken = async (token) => {
    try {
      await axios.get('/api/verify-token', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      setIsAuthenticated(true);
    } catch (error) {
      console.error('Token verification failed:', error);
      localStorage.removeItem('idToken');
      setIsAuthenticated(false);
      redirectToCognitoLogin();
    }
  };

  const redirectToCognitoLogin = () => {
    const clientId = '4o9h3e2ds26r5pinq7th3gl1go'; 
    const redirectUri = 'https://sales.exuminstruments.com'; 
    const cognitoDomain = 'https://exum-sales.auth.us-east-1.amazoncognito.com'; 
    window.location.href = `${cognitoDomain}/login?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&scope=email+openid`;
  };

    // Sign-out function
    const handleSignOut = () => {

      const clientId = '4o9h3e2ds26r5pinq7th3gl1go'; 
      const redirectUri = 'https://sales.exuminstruments.com'; 
      const cognitoDomain = 'https://exum-sales.auth.us-east-1.amazoncognito.com'; 
      // Remove tokens from localStorage
      localStorage.removeItem('idToken');
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
  
      // Redirect to Cognito logout endpoint
      const logoutUrl = `${cognitoDomain}/logout?client_id=${clientId}&logout_uri=${redirectUri}`;
      console.log(logoutUrl);
      window.location.href = logoutUrl;
    };

  return (
    <div>
      {isAuthenticated ? (
        <>
          <header className="header">
            <div className="logo"></div>
            <button onClick={handleSignOut}>Sign Out</button>
          </header>
          <div>
            <CompanyKeyForm />
            <ShowRecords />
          </div>
        </>
      ) : (
        <div className="redirecting-container">
          <div className="spinner"></div>
          <p className="redirecting-text">Redirecting to login, please wait...</p>
        </div>
      )}
    </div>
  );
}

export default App;
