import React, { useState, useEffect } from 'react';
import { FiCopy } from 'react-icons/fi'; // Importing an icon for the copy button
import './index.css'; // Importing the CSS file

function ShowRecords() {
    const [records, setRecords] = useState([]);
    const [sortKey, setSortKey] = useState('company_name');
    const [sortOrder, setSortOrder] = useState('asc');

    useEffect(() => {
        const fetchRecords = async () => {
            try {
                const response = await fetch('/api/records');
                let data = await response.json();
                data = sortRecords(data, sortKey, sortOrder);
                setRecords(data);
            } catch (error) {
                console.error('Error fetching records:', error);
            }
        };
        fetchRecords();
    }, [sortKey, sortOrder]);

    const sortRecords = (records, key, order) => {
        return records.sort((a, b) => {
            if (order === 'asc') {
                if (key === 'installCount') {
                    return a.installCount - b.installCount;
                } else {
                    return a[key].localeCompare(b[key]);
                }
            } else {
                if (key === 'installCount') {
                    return b.installCount - a.installCount;
                } else {
                    return b[key].localeCompare(a[key]);
                }
            }
        });
    };

    const handleSort = (key) => {
        if (sortKey === key) {
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        } else {
            setSortKey(key);
            setSortOrder('asc');
        }
    };

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text);
        alert("Copied to clipboard!");
    };

    return (
        <div class="container">
            <h2>Records</h2>
            <table>
                <thead>
                    <tr>
                    <th onClick={() => handleSort('company_name')}>
                        Company Name {sortKey === 'company_name' ? (sortOrder === 'asc' ? ' ↑' : ' ↓') : ''}
                    </th>
                    <th >
                        Company Registration Key 
                    </th>
                    <th onClick={() => handleSort('installCount')}>
                        Install Count {sortKey === 'installCount' ? (sortOrder === 'asc' ? ' ↑' : ' ↓') : ''}
                    </th>
                    <th>
                    </th>
                    </tr>
                </thead>
                <tbody>
                {records.map((record) => (
                    <tr key={record.companyToken}>
                        <td>{record.company_name}</td>
                        <td>{record.companyToken}</td>
                        <td>{record.installCount}</td>
                        <td style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                            <button className="copy-btn" onClick={() => copyToClipboard(record.companyToken)}>
                                <FiCopy size={20} color="#404040" />
                            </button>
                        </td>
                    </tr>
                ))}
            </tbody>
            </table>
        </div>
    );
}

export default ShowRecords;


